import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import noticias06 from "../../../../static/img/blog/noticias-06-.png";


const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Elecciones presidenciales de EE. UU. y su impacto en los mercados'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias06}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                28 Oct. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Miguel Bermejo
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>Elecciones presidenciales de EE. UU. y su impacto en los mercados</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>Las elecciones presidenciales de EE. UU., que se celebrarán el 3 de noviembre de 2020, apuntan a
                        ser unas de las más
                        polémicas de los últimos años. En los años electorales “normales”, basados en la incertidumbre
                        de quién prevalecerá al poder,
                        los inversores suelen adoptar un enfoque más conservador en los mercados. Sin embargo, en este
                        año la elección presidencial no
                        figura ser “normal”, debido al papel que jugará la respuesta del gobierno estadounidense por el
                        brote de COVID-19, y el efecto
                        que ha tenido en la deuda pública de EE. UU. </p>

                    <p>El impacto que un presidente puede ocasionar en la economía y el mercado depende de su capacidad
                        para promulgar leyes,
                        la cual está sujeta al apoyo que pueda tener tanto de la Cámara de Representantes, como del
                        Senado. En este sentido, derivado
                        de la crisis económica y sanitaria ocasionada por el coronavirus, especialistas del mercado
                        examinaron cinco posibles escenarios
                        que podrían desarrollarse en la recta final hacia las elecciones de noviembre. </p>

                    <p><b>Un barrido democrático</b></p>

                    <p>La mayoría de las encuestas muestran al candidato presidencial demócrata, Joe Biden, al frente
                        del presidente
                        Donald Trump a nivel nacional. En este sentido, asociado a la incertidumbre que existe ante una
                        nueva administración,
                        si Biden gana la presidencia y el Partido Demócrata arrasa en ambas Cámaras del Congreso, es
                        probable que el mercado
                        reaccione negativamente, al menos a corto plazo.</p>

                    <p>De acuerdo con Sam Stovall, estratega jefe de inversiones de CFRA Research, a lo largo de la
                        historia,
                        una victoria Demócrata en todos los ámbitos ha desencadenado un descenso medio del mercado. Sin
                        embargo, después de
                        la conmoción inicial, el mercado logra recuperar las ganancias a finales de año. Por su parte,
                        Allison Schrager, economista
                        y miembro principal del Manhattan Institute, señaló que esta clase de victoria podría causar el
                        surgimiento de políticas más
                        izquierdistas, lo que sería perjudicial para los mercados financieros.</p>

                    <p>A pesar de las posibles consecuencias negativas que una victoria demócrata podría conllevar, los
                        especialistas
                        concuerdan que los mercados se encuentran preparados para la victoria de Biden.</p>

                    <p><b>Biden gana las elecciones, pero el Partido Republicano retiene el Senado</b></p>

                    <p>De salir victorioso Biden, pero con los Republicanos en control del Senado, será difícil para los
                        Demócratas
                        promulgar cualquier cambio importante en la política fiscal. Debido a que la plataforma
                        Demócrata no se encuentra
                        orientada hacia el crecimiento, una victoria en este escenario sería vista de forma positiva por
                        los mercados, pues
                        significaría que una legislación desagradable no será impuesta a los inversores en términos de
                        impuestos y regulación, señaló Stovall.</p>

                    <p>Sin embargo, de darse esta victoria, Stacy Swanson, asesora de política pública de la firma de
                        abogados internacional,
                        Squire Patton Boggs, comenta que Republicanos y Demócratas pueden encontrar un punto en común
                        sobre temas misceláneos.</p>

                    <p><b>Kamala Harris como vicepresidenta calma el nerviosismo de los inversionistas</b></p>

                    <p>Durante el proceso de elecciones, el tener a Harris como candidata a la vicepresidencia ha sido
                        beneficioso para Biden,
                        ya que ha logrado disminuir el nerviosismo en Wall Street y Silicon Valley. </p>

                    <p>En línea con Swanson, la candidatura de Harris ha mostrado popularidad con los líderes en Silicon
                        Valley, ya que está
                        familiarizada con estas compañías desde sus días como fiscal general de California, donde ha
                        reportado que siempre ha tratado
                        de trabajar con las compañías tecnológicas y no contra ellas, por lo que están conscientes de
                        que Harris no es una antagonista.</p>

                    <p><b>Trump gana un segundo mandato en noviembre</b></p>

                    <p>De salir victorioso Trump, es probable que los mercados reacciones positivamente en el corto
                        plazo, sin embargo, a
                        largo plazo el mercado podría presentar una mayor volatilidad. De acuerdo con Schrager y
                        Swanson, uno de los motivos por
                        el cual los mercados tienen mucha volatilidad es por la incertidumbre que Trump genera, por lo
                        que su victoria podría conllevar
                        cierta inestabilidad durante los próximos cuatro años.</p>

                    <p>Si bien las políticas pro-empresariales del presidente han contribuido a generar considerables
                        ganancias en el mercado
                        en los últimos cuatro años, la actual pandemia ha creado presión para considerar un alza de
                        impuestos ante el nivel de deuda
                        pública que ha generado, por tanto, las finanzas públicas serán un difícil tema a tratar. </p>

                    <p><b>Un resultado impugnado y la pandemia de COVID-19</b></p>

                    <p>Uno de los resultados menos deseables para los mercados sería un resultado electoral
                        controversial. De darse algo
                        cercano a un resultado impugnado, es decir, si la mitad del electorado no acepta los resultados,
                        las instituciones de las que
                        dependen los mercados para su estabilidad a largo plazo resentirán los efectos de esta decisión,
                        generando un problema, también
                        de largo plazo, que afectará a todo el mercado. </p>

                    <p>De acuerdo con Stovall, si Trump gana un segundo mandato, el mercado rebota a corto plazo porque
                        no existiría la
                        incertidumbre de una nueva administración. En este sentido, el tema dominante será cómo se
                        desarrolla la pandemia de COVID-19 y
                        la búsqueda de una vacuna, de manera que el mercado se enfocará en regresar a los negocios y
                        crear una defensa contra el virus que
                        permita a América regresar a la normalidad.</p>

                    <p>En contraste, si Biden gana la presidencia, la relativa moderación como candidato Demócrata que
                        muestra es un alivio para los
                        mercados, ya que no busca reinventar la economía, de manera que el virus se mantendría como su
                        prioridad.</p>

                    <p>Referencias Bibliográficas</p>
                    <p>
                        <ul>
                            <li>Schnitzel, M. (2020). What will the US election mean for the markets?. Octubre 06,
                                2020. <a href="https://content.irmagazine.com/story/ir-magazine-fall-2020/page/12"
                                         target="_blank"> IR Magazine</a>.
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </Single>
    )
}

export default Detail
